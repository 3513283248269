import React from "react"
import Skeleton from "react-loading-skeleton"
import "./Skeleton.css"

export default () => (
  <div className="skeleton-grid">
    {new Array(16).fill(1).map((_, idx) => (
      <div key={idx} style={{ margin: "0 auto" }}>
        <Skeleton height={250} width={300} count={1} />
      </div>
    ))}
  </div>
)
