import React, { useState, useRef } from "react"
import ReactBnbGallery from "react-bnb-gallery"
import SkeletonGrid from "./Skeleton"
import "react-bnb-gallery/dist/style.css"
import "./PhotoGallery.css"

function useImageLoaded() {
  const [isLoaded, setIsLoaded] = useState(false)
  const elementRefs = useRef([])
  const loadedCount = useRef(0)

  function incrementLoadedCount() {
    loadedCount.current++
    if (loadedCount.current === elementRefs.current.length && !isLoaded) {
      elementRefs.current = []
      setIsLoaded(true)
    }
  }

  return [
    isLoaded,
    elementRef => {
      if (elementRef) {
        elementRefs.current.push(elementRef)
        if (elementRef.complete) {
          incrementLoadedCount()
        } else {
          elementRef.addEventListener("load", incrementLoadedCount)
        }
      }
    },
  ]
}

function resizeGridItem(item) {
  const grid = document.querySelector(".photo-gallery")
  const rowHeight = parseInt(
    window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
  )
  const rowGap = parseInt(
    window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
  )
  const rowSpan = Math.ceil(
    (item.getBoundingClientRect().height + rowGap) / (rowHeight + rowGap)
  )
  item.style.gridRowEnd = "span " + rowSpan
}

function resizeAllGridItems() {
  const allItems = document.getElementsByClassName("photo-gallery-item")
  for (let x = 0; x < allItems.length; x++) {
    resizeGridItem(allItems[x])
  }
}

function PhotoGallery({ photos }) {
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  const galleryPhotos = useRef(photos.map(p => p.src))
  const [areImagesLoaded, ref] = useImageLoaded()

  if (areImagesLoaded) {
    resizeAllGridItems()
    window.addEventListener("resize", resizeAllGridItems, { passive: true })
  }

  return (
    <React.Fragment>
      {!areImagesLoaded && <SkeletonGrid />}
      <ReactBnbGallery
        show={isOpen}
        photos={galleryPhotos.current}
        activePhotoIndex={photoIndex}
        onClose={() => setIsOpen(false)}
      />
      <div className="photo-gallery">
        {photos.map((image, idx) => (
          <img
            ref={ref}
            className="photo-gallery-item"
            key={image.src}
            src={image.src}
            alt={image.name}
            onClick={() => {
              setPhotoIndex(idx)
              setIsOpen(true)
            }}
          />
        ))}
      </div>
    </React.Fragment>
  )
}

export default PhotoGallery
